<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="800"
    transition="dialog-bottom-transition"
    content-class="custom-dialog rounded-b-0"
    >
    <v-card
      class="rounded-b-0 rounded-t-xl"
      height="70vh"
      >
      <v-card-title>
        Seleccione un perfil
      </v-card-title>

      <v-list>
        <template
          v-for="profile in profiles"
          >
          <v-list-item
            @click="share(profile.id)"
            >
            <v-list-item-avatar>
              <v-img 
                v-if="profile.picture"
                :src="url + profile.picture.thumbUrl"
                ></v-img>
              <v-icon
                v-else
                >
                mdi-account-circle
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              {{ profile.name }}
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { ProfileChats } from '@/graphql/queries/profiles'

export default {
  data: () => ({
    dialog: false,
    profiles: []
  }),

  created () {
    this.fetchProfiles()
  },

  methods: {
    fetchProfiles () {
      this.$apollo.query({
        query: ProfileChats,
      }).then ( res => {
        this.profiles = res.data.profileChats
      })
    },

    share (id) {
      this.$emit('share', id)
      this.dialog = false
    }
  },

  computed: {
    ...mapGetters(['url'])
  }
}
</script>
